<template>
  <section class="tables">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Payment Modes</h3>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3 col-lg-3">
                <select
                  class="custom-select form-control"
                  @change="sortByType"
                  v-model="type"
                >
                  <option value="" selected>Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive pb-2">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Method</th>
                  <th>Enabled</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in payments" :key="payment.id">
                  <td>
                    <code>{{ payment.name }}</code>
                  </td>

                  <td>
                    <toggle-button
                      @change="onToggleChange(payment.id, $event)"
                      v-model="payment.active"
                      :labels="{ checked: true, unchecked: false }"
                    />
                  </td>
                  <td>{{ payment.description }}</td>
                  <td>
                    <router-link
                      class="badge badge-info"
                      :to="`/settings/edit-payment-modes/${payment.id}`"
                    >
                      <span v-if="payment.active == 1">Manage</span>
                      <span v-else>Set up</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 pt-3 text-center" v-if="payments == ''">
              No data found!
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "payment-list",
  props: {
    edit: {
      active: Boolean,
      name: String,
      description: String,
      image_id: Number,
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      payments: "",
      payment: this.edit,
      showLoader: false,
      type: "",
      body: ""
    };
  },
  mounted() {
    this.paymentModes();
  },
  methods: {
    async paymentModes() {
      this.showLoader = true;
      const { data } = await setingsApi.paymentModes();
      this.payments = data.data.data;
      this.payments.forEach((val) => {
        if (val.active == 1) {
          val.active = true;
        } else {
          val.active = false;
        }
      });
      this.showLoader = false;
    },
    async sortByType() {
      if (this.type === "") {
        this.paymentModes();
      } else {
        this.showLoader = true;
        const { data } = await setingsApi.sortByPaymentStatus(this.type);
        this.payments = data.data.data;
        this.showLoader = false;
      }
    },
    async onToggleChange(id, event) {
      this.body = { active: event.value };
      this.showLoader = true;
      const data = await setingsApi.updatePaymentMode(id, {
        ...this.body,
      });
      this.showLoader = false;
      this.paymentModes();
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

<style scoped></style>
